import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Box, ListItem, Typography, List } from '@material-ui/core';

import Download from "../components/download"

import { Container, Row, Col, Card } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const Courses = () => (
  <Layout>
    <Seo title="Course Details" description="3 years and 5 years LLB fListl Course Details" />
    <ThemeProvider theme={theme}>
<Box style={{ position:'relative', bottom:40 }}>
<Container>
    <Row>
        <Col xs={12} lg={8} className="bg-white list-icon">
        <div className="p-3 list-icon mt-4">
           <Typography variant="h5" gutterBottom style={{ color:'#453260' }}>KARNATAKA STATE LAW UNIVERSITY PRESCRIBED SYLLABUS</Typography>
           <Typography variant="h6" gutterBottom style={{ color:'#453260' }}><b>Course Prescribed For 3 years LL.B., Degree Course</b></Typography>
           <Typography variant="h6" gutterBottom className="pt-2">First Semester:</Typography>
           <List className="ps-2">
            <ListItem> Course I Contract –I</ListItem>
            <ListItem> Course II Constitution</ListItem>
            <ListItem> Course III Law of Torts</ListItem>
            <ListItem> Course IV Family Law-I;: Hindu Law</ListItem>
            <ListItem> Course V Criminal Law-I: Indian Penal Code</ListItem>
           </List>

           <Typography variant="h6" gutterBottom>Second Semester:</Typography>
           <List className="ps-2">
           <ListItem>Course I Contract-II</ListItem>
            <ListItem>Course II Company Law</ListItem>
            <ListItem>Course III Property Law</ListItem>
            <ListItem>Course IV Administrative Law</ListItem>
            <ListItem>Course V Family Law-II : Mohammedan Law & Indian Succession Act</ListItem>
           </List>

           <Typography variant="h6" gutterBottom>Third Semester:</Typography>
           <List className="ps-2">
           <ListItem>Course I Environmental Law</ListItem>
            <ListItem>Course II Labor Law</ListItem>
            <ListItem>Course III Criminal Kaw-II: Criminal Procedure Code, JJ Act and Probation of Offenders Act</ListItem>
            <ListItem>Course IV Jurisprudence</ListItem>
            <ListItem>Course V Clinical Course-I: Professional Ethics & Professional Accounting System</ListItem>
           </List>

           <Typography variant="h6" gutterBottom>Fourth Semester:</Typography>
           <List className="ps-2">
            <ListItem> Course I Public International Law</ListItem>
            <ListItem> Course II Optional-I: Human Rights Law & Practice / Insurance Law</ListItem>
            <ListItem> Course III Optional-II: Banking Law / Right to information Law</ListItem>
            <ListItem> Course IV Clinical Course-II: Alternate Dispute Resolution Systems</ListItem>
            <ListItem> Course V Clinical Course-I: Professional Ethics & Professional Accounting System</ListItem>
           </List>

           <Typography variant="h6" gutterBottom>Fifth Semester:</Typography>
           <List className="ps-2">
            <ListItem>Course I Civil Procedure Code & Limitation Act</ListItem>
            <ListItem>Course II Optional-III: Intellectual Property Rights –I / Penology & Victimology</ListItem>
            <ListItem>Course III Optional-IV: Interpretation of Statutes & Principles of Legislation / Competition Law</ListItem>
            <ListItem>Course IV Clinical Course-III: Drafting, Pleading & Conveyance</ListItem>
           </List>

           <Typography variant="h6" gutterBottom>Sixth Semester:</Typography>
           <List className="ps-2">
           <ListItem>Course I law of Evidence</ListItem>
            <ListItem>CoursCourse II Taxation</ListItem>
            <ListItem>Course III Optional-V: Intellectual Property Rights-II / White Collar Crimes</ListItem>
            <ListItem>Course IV Optional-VI: Woman & Criminal Law & Law Relating to Child/ International Trade Economics</ListItem>
           </List>

           <Typography variant="h6" className="pb-2" style={{ color:'#453260' }}><b>Course Prescribed For 5 years LL.B., Degree Course</b></Typography>

           <Typography variant="h6" gutterBottom>First Semester:</Typography>
           <List className="ps-2">
           <ListItem>Kannada/Special English/ French (Kannada is compListsory for all those who have studied Kannada as a subject at the SSLC level)</ListItem>
            <ListItem>Sociology-I</ListItem>
            <ListItem>Political Science-I</ListItem>
            <ListItem>Elements of research</ListItem>
           </List>

           <Typography variant="h6" gutterBottom>Second Semester:</Typography>
           <List className="ps-2">
           <ListItem>English</ListItem>
            <ListItem>Sociology-II</ListItem>
            <ListItem>Political Science-II</ListItem>
            <ListItem>Economics-I</ListItem>
           </List>

           <Typography variant="h6" gutterBottom>Third Semester:</Typography>
           <List className="ps-2">
           <ListItem>History-I</ListItem>
            <ListItem>Political Science-III</ListItem>
            <ListItem>Economics-II</ListItem>
            <ListItem>Constitution</ListItem>
           </List>

           <Typography variant="h6" gutterBottom>Fourth Semester:</Typography>
           <List className="ps-2">
           <ListItem>Economics-III</ListItem>
            <ListItem>History-II</ListItem>
            <ListItem>Contract-I</ListItem>
            <ListItem>Psychology</ListItem>
           </List>

           <Typography variant="h6" gutterBottom>Fifth Semester:</Typography>
           <List className="ps-2">
           <ListItem>Contract-II</ListItem>
            <ListItem>Law of Torts</ListItem>
            <ListItem>Family Law-I: Hindu Law</ListItem>
            <ListItem>Criminal Law-I: Indian Penal Code</ListItem>
           </List>

           <Typography variant="h6" gutterBottom>Sixth Semester:</Typography>
           <List className="ps-2">
           <ListItem>Company Law</ListItem>
            <ListItem>Property Law</ListItem>
            <ListItem>Administrative Law</ListItem>
            <ListItem>Family Law –II: Mohammedan Law & Indian Succession Act</ListItem>
           </List>

           <Typography variant="h6" gutterBottom>Seventh Semester:</Typography>
           <List className="ps-2">
           <ListItem>Environmental Law</ListItem>
            <ListItem>Labour Law</ListItem>
            <ListItem>Criminal Law - II: Criminal Procedure Code, etc</ListItem>
            <ListItem>Jurisprudence</ListItem>
            <ListItem>Clinical course-I: Professional Ethics and Professional Accounting System</ListItem>
           </List>

           <Typography variant="h6" gutterBottom>Eighth Semester:</Typography>
           <List className="ps-2">
           <ListItem>Public International Law</ListItem>
            <ListItem>Optional-I: Human Rights Law and Practice / Right to Information</ListItem>
            <ListItem>Optional-II: Banking Law / Insurance Law</ListItem>
            <ListItem>Clinical course-II: Alternative Dispute Resolution Systems</ListItem>
           </List>

           <Typography variant="h6" gutterBottom>Ninth Semester:</Typography>
           <List className="ps-2">
           <ListItem>Civil Procedure Code and Limitation Act</ListItem>
            <ListItem>Optional-III: Intellectual Property Rights-I / Penology & Victimology</ListItem>
            <ListItem>Optional-IV: Interpretation of Statutes & Principles of Legislation / Competition Law</ListItem>
            <ListItem>Clinical course-III: Drafting, Pleading and Conveyance</ListItem>
           </List>

           <Typography variant="h6" gutterBottom>Tenth Semester:</Typography>
           <List className="ps-2">
           <ListItem>Law of Evidence</ListItem>
            <ListItem>Taxation</ListItem>
            <ListItem>Optional – V: Intellectual Property Rights-II / White Collar Crimes</ListItem>
            <ListItem>Optional – VI: Women and Criminal Law & Law relating to child / Law Relating to International Trade</ListItem>
           <ListItem>Clinical course-IV: Moot Court exercise and Internship</ListItem>
           </List>

           
        </div>
        </Col>
        <Col xs={12} lg={4} className="bg-white">
        <Card className="shadow mb-2 bg-light mt-5">
  <Card.Body>
<Download/>
</Card.Body>
</Card>
 
</Col>

    </Row>
</Container>
</Box>
</ThemeProvider>

  </Layout>
)

export default Courses
